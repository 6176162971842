// ==========================================================================
// FAQ section
// ==========================================================================

.l-FAQ {
  //background-color: $grey-decent;

  .faq-wrapper {
    display: block;
    margin: 2em 0;
  }

  article {
    &.dropdown {
      margin: 1em 0;
      padding: 1em;
      position: relative;

      i {
        position: absolute;
        right: 1em;
        top: 1em;
        transition: all 0.3s;
        color: $brand-3;
      }

      h5 {
        margin: 0;
        width: 80%;
      }

      .answer {
        display: none;
      }
    }

    &.border {
      border: solid 2px $black;
      border-radius: 5px;
    }

    &.show {
      i {
        transform: rotate(180deg);
        transition: all 0.3s;
      }

      .answer {
        display: block;
      }
    }

    &:hover {
      cursor: pointer;
      border: solid 2px $brand-3;
    }
  }
}

@include media-breakpoint-up(md) {
  .l-FAQ {
    .faq-wrapper {
      display: flex;

      .column {
        width: 50%;
        margin: 0.5em;
      }
    }
  }
}
