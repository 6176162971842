// ==========================================================================
// CTA section
// ==========================================================================

.l-cta {
  background-color: $grey-decent;

  .content {
    height: 100%;
    text-align: center;
  }

  .d-grid {
    grid-column: 1;
  }

  .intro,
  .visual {
    margin: 2em;
  }
}
