// ==========================================================================
// Hero section
// ==========================================================================

input[type="submit"].sending {
  background-color: grey;
}

input[type="submit"].completed {
  background-color: greenyellow;
}

input[type="submit"].failed {
  background-color: orange;
}

.l-hero {
  background-color: $white;
  background-image: url('/images/grassy-field.jpg');
  background-position: center -20vw;
  background-repeat: no-repeat;
  background-size: auto;
  min-height: inherit;

  .content {
    margin-left: auto;
    margin-right: auto;
    max-width: 640px;

    .logo {
      width: 130px;
    }

    .powered-by {
      text-align: left;
      color: white;
      font-weight: normal;
      justify-content: flex-end;

      img {
        width: 66px;
      }
    }

    nav {
      grid-area: nav;
      text-align: right;

      a {
        color: $white;
        border-bottom: 1px solid;
      }
    }
  }

  // .wizard {
  //   background-color: $white;
  //   margin-left: auto;
  //   margin-right: auto;
  //   max-width: 640px;
  //
  //   h2 {
  //     display: block;
  //   }
  //
  //   .radio-image-selector {
  //     .d-grid {
  //       grid-gap: 0 0.4em;
  //       grid-template-columns: 1fr 1fr 1fr;
  //     }
  //
  //     input[type="radio"]:checked ~ label {
  //       box-shadow: 0 0 2px 2px #3499D5;
  //       border: 0;
  //     }
  //   }
  //
  //   // .edition {
  //   //   .vdp-datepicker__calendar {
  //   //     max-width: 100%;
  //   //   }
  //   //
  //   //   .vdp-datepicker::after {
  //   //     color: $grey-medium;
  //   //     content: '\e806';
  //   //     font-family: "kecleon-iconfont";
  //   //     font-size: 1.5em;
  //   //     line-height: 2.25em;
  //   //     pointer-events: none;
  //   //     position: absolute;
  //   //     right: 0.5em;
  //   //     top: 0;
  //   //   }
  //   // }
  //
  //   // .contact .d-grid {
  //   //   grid-template-areas: "title" "fname" "lname" "email" "phone" "company" "button";
  //   //   grid-template-columns: 1fr;
  //   //
  //   //   h2 {
  //   //     grid-area: title;
  //   //   }
  //   //
  //   //   input[name="fname"] {
  //   //     grid-area: fname;
  //   //   }
  //   //
  //   //   input[name="lname"] {
  //   //     grid-area: lname;
  //   //   }
  //   //
  //   //   input[name="phone"] {
  //   //     grid-area: phone;
  //   //   }
  //   //
  //   //   input[name="email"] {
  //   //     grid-area: email;
  //   //   }
  //   //
  //   //   input[name="company"] {
  //   //     grid-area: company;
  //   //   }
  //   //
  //   //   button {
  //   //     grid-area: button;
  //   //   }
  //   // }
  //
  //   // .summary {
  //   //   .format {
  //   //     width: 31%;
  //   //     display: block;
  //   //     text-align: center;
  //   //     -moz-transition: all 100ms ease-in;
  //   //     background-color: $grey-bright;
  //   //     border: 1px solid $grey-light;
  //   //     border-radius: 4px;
  //   //
  //   //     img {
  //   //       margin: 0.5em 1.5em 0;
  //   //       width: calc(100% - 3em);
  //   //       box-shadow: 0 1px 2px 0 rgba($black, 0.6);
  //   //     }
  //   //   }
  //   //
  //   //   h5 {
  //   //     border-bottom: 1px solid $grey-default;
  //   //     position: relative;
  //   //   }
  //   //
  //   //   .icon-streamline-icon-pencil-2-svgomg {
  //   //     color: $grey-default;
  //   //     position: absolute;
  //   //     right: 0;
  //   //     font-size: 0.8em;
  //   //   }
  //   // }
  //
  //   // .closure {
  //   //   background-color: $grey-bright;
  //   //
  //   //   button {
  //   //     max-width: inherit;
  //   //   }
  //   //
  //   //   .notification {
  //   //     &.completed {
  //   //       background-color: $success;
  //   //     }
  //   //
  //   //     &.error {
  //   //       background-color: $attention;
  //   //     }
  //   //
  //   //     p {
  //   //       opacity: 0.7;
  //   //     }
  //   //   }
  //   //
  //   //   .text-muted {
  //   //     line-height: 1.1em;
  //   //   }
  //   // }
  //
  //   // .closure .d-grid.price-input {
  //   //   grid-template-areas: "title" "proposedPrice-title" "proposedPrice-field" "wunschpreis-title" "wunschpreis-field" "savingPrice-field";
  //   //   grid-template-columns: 1fr;
  //   //   align-items: center;
  //   //   grid-gap: 0.75em;
  //   //
  //   //   h2 {
  //   //     grid-area: title;
  //   //   }
  //   //
  //   //   p {
  //   //     margin: 0;
  //   //   }
  //   //
  //   //   .proposedPrice-title {
  //   //     grid-area: proposedPrice-title;
  //   //   }
  //   //
  //   //   .proposedPrice-field {
  //   //     grid-area: proposedPrice-field;
  //   //     background: $grey-light;
  //   //     border: 1px solid $grey-light;
  //   //     border-radius: 4px;
  //   //     padding: 1em;
  //   //   }
  //   //
  //   //   .wunschpreis-title {
  //   //     grid-area: wunschpreis-title;
  //   //   }
  //   //
  //   //   .wunschpreis-field {
  //   //     grid-area: wunschpreis-field;
  //   //     display: flex;
  //   //     border: 1px solid #555;
  //   //     border-radius: 4px;
  //   //     overflow: hidden;
  //   //
  //   //     input {
  //   //       width: 90%;
  //   //       border: none;
  //   //     }
  //   //
  //   //     .euro {
  //   //       width: 10%;
  //   //       background: $grey-bright;
  //   //       padding-top: 0.5em;
  //   //       box-sizing: border-box;
  //   //       text-align: center;
  //   //       line-height: 2.25;
  //   //     }
  //   //   }
  //   //
  //   //   .savingPrice-field {
  //   //     color: #845E1E;
  //   //     grid-area: savingPrice-field;
  //   //     background: #FBD58B;
  //   //     border: 1px solid #FBD58B;
  //   //     border-radius: 4px;
  //   //     padding: 0.5em;
  //   //     text-align: center;
  //   //   }
  //   //
  //   //   button {
  //   //     grid-area: button;
  //   //   }
  //   // }
  // }
}

@include media-breakpoint-up(sm) {
  .wizard {
    box-shadow: 0 0 4px 4px rgba($black, 0.2);
  }
}

@media screen and (min-width: 500px) {
  .l-hero .wizard .closure .d-grid.price-input {
    grid-template-areas: "title title" "proposedPrice-title proposedPrice-field" "wunschpreis-title wunschpreis-field" "savingPrice-field savingPrice-field";
    grid-template-columns: 0.5fr 1fr;
    grid-gap: 1em;
  }
}

@include media-breakpoint-up(md) {
  .l-hero {
    background-position: center top;
    padding: 4rem 0;
    background-size: 100% auto;

    .content {
      grid-template-columns: auto auto;
      grid-gap: 2rem;
      max-width: 700px;
    }

    .wizard {
      .radio-image-selector {
        .d-grid {
          grid-gap: 0 1em;
        }
      }

      .contact .d-grid {
        grid-gap: 0.5em 1em;
        grid-template-areas:
          "title title"
          "fname lname"
          "email phone"
          "company company"
          "button button";
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
