// ==========================================================================
// Validations for forms.
// Based on https://getbootstrap.com/docs/4.0/components/forms/?
// Changed to fit HTML 5
// ==========================================================================

// text inputs fields
input[type="email"],
input[type="password"],
input[type="text"] {
  &:valid {
    // border-color: $success;
    border: 2px solid $success;
  }

  &:invalid {
    // border-color: $danger;
    border: 2px solid $danger;
  }
}

// checkboxes
input[type="radio"] {
  &:invalid {
    background-color: $danger;
  }
}

// feedback messages
.valid-feedback {
  @extend small;
  // @extend .mt-1;

  color: $success;
}

.invalid-feedback {
  // @extend small;
  // @extend .mt-1;
  // @extend .animated;
  // @extend .shake;

  color: $danger;
}
