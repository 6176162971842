// ==========================================================================
// Contact section
// ==========================================================================

.l-text-only.l-contact .content {
  background: none;
}

.l-contact {
  .content .intro {
    margin: 1em 0 2.5em;
  }

  .h-2 {
    margin: 1em 0 0.5em;
  }
}

.contact-content {
  margin: 0 auto;
  width: 90%;

  div {
    margin: 0 0 2em;
  }

  .d-grid {
    grid-template: "icon" "link" / 100%;

    img {
      grid-area: icon;
    }

    .h-4 {
      grid-area: link;
    }
  }

  img {
    width: 52px;
    max-width: 52px;
    margin: 0 auto;
  }

  a {
    color: black;
    text-align: center;
    margin: 0 auto;

    &:hover {
      color: $brand-3;
    }
  }
}

@media screen and (min-width: 360px) {
  .content .contact-content {
    width: auto;

    .d-grid {
      grid-template: "icon link" / 20% auto;
    }

    a {
      text-align: left;
      margin: 0.5em 0 0 0.3em;
    }
  }
}

@include media-breakpoint-up(sm) {
  .content .contact-content {
    width: 72%;

    .d-grid {
      grid-template: "icon link" / 20% auto;
    }

    img {
      width: 57%;
    }

    a {
      margin: 0.25em 0 0 0.1em;
      font-size: 2em;
      line-height: 1.1em;
    }
  }
}
