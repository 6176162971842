// ==========================================================================
// Testemonial section
// ==========================================================================

.l-testemonial {
  background: $grey-bright;
  margin: 2em 0;
  padding: 2em 0;

  .d-flex {
    flex-wrap: wrap;
  }

  .intro {
    flex-basis: 100%;
  }

  img {
    width: 30%;
    height: auto;
    flex-grow: 1;
    margin: 2em;
  }
}

@include media-breakpoint-up(md) {
  .l-testemonial {
    img {
      width: 15%;
    }
  }
}
