// ==========================================================================
// Helper classes and tags
// ==========================================================================

// Small
// -------------------------

small,
small a,
small p {
  font-size: 80%;
  line-height: inherit;
}

// Mute
// -------------------------

.text-muted {
  color: $grey-default;
}

// Accessibility
// -------------------------

.sr-only {
  display: none;
}

// Text-Align
// -------------------------

.text-left {
  text-align: left;
}

.text-xs-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

@include media-breakpoint-up(md) {
  .text-md-left {
    text-align: left;
  }
}

@include media-breakpoint-up(xl) {
  .text-xl-left {
    text-align: left;
  }
}

.cursor {
  cursor: pointer;
}

// .text-black {
//   color: $black-default;
//   font-size: 1rem;
//   font-weight: $font-weight-regular;
//   line-height: 1.313rem;
// }
//
// .text-grey {
//   color: $grey-default;
//   font-size: 1rem;
//   font-weight: $font-weight-regular;
//   line-height: 1.438rem;
// }
//
// .text-white {
//   color: $white;
//   font-size: 1.188rem;
//   font-weight: $font-weight-light;
//   line-height: 1.438rem;
// }
//
// @include media-breakpoint-up(xl) {
//   .text-white {
//     font-size: 1.375rem;
//     letter-spacing: 0.03px;
//     line-height: 1.938rem;
//   }
// }

// Visible + Hidden Reference Cases
// -------------------------

// Hidden only on xs
//.d-none .d-sm-block

// Hidden only on sm
//.d-sm-none .d-md-block

// Hidden only on md
//.d-md-none .d-lg-block

// Hidden only on lg
//.d-lg-none .d-xl-block

// Hidden only on xl
//.d-xl-none

// Visible on all
//.d-block

// Visible only on xs
//.d-block .d-sm-none

// Visible only on sm
//.d-none .d-sm-block .d-md-none

// Visible only on md
//.d-none .d-md-block .d-lg-none

// Visible only on lg
//.d-none .d-lg-block .d-xl-none

// Visible only on xl
//.d-none .d-xl-block

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

@include media-breakpoint-up(xs) {
  .d-xs-block {
    display: block !important;
  }

  .d-xs-none {
    display: none !important;
  }
}

@include media-breakpoint-up(sm) {
  .d-sm-block {
    display: block !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }
}

@include media-breakpoint-up(md) {
  .d-md-block {
    display: block !important;
  }

  .d-md-none {
    display: none !important;
  }
}

@include media-breakpoint-up(lg) {
  .d-lg-block {
    display: block !important;
  }

  .d-lg-none {
    display: none !important;
  }
}

@include media-breakpoint-up(xl) {
  .d-xl-block {
    display: block !important;
  }

  .d-xl-none {
    display: none !important;
  }
}

@include media-breakpoint-up(xxl) {
  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-none {
    display: none !important;
  }
}
