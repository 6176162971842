// ==========================================================================
// Forms > Select field, e.g. used in product-template.
// Based on http://wtfforms.com
// ==========================================================================

.select {
  color: $grey-dark;
  display: inline-block;
  position: relative;
}

.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $white;
  border-radius: 2px;
  border: 1px solid;
  color: $grey-dark;
  cursor: pointer;
  display: inline-block;
  line-height: 1.5;
  margin: 0;
  padding: 0.5rem 2.25rem 0.5rem 1rem;
  //width: calc(100vw - 20px);
  width: 100%;
}

/* Undo the Firefox inner focus ring */
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 $black;
}

/* Dropdown arrow */
// .select::after,
.select .selector-wrapper::after {
  border-bottom: 0.35rem solid transparent;
  border-left: 0.35rem solid transparent;
  border-right: 0.35rem solid transparent;
  border-top: 0.35rem solid;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.15rem;
  pointer-events: none;
  position: absolute;
  right: 1.25rem;
  top: 50%;
  width: 0;
}

.select select[style="display: none;"],
.select .selector-wrapper::after {
  top: 67%;
}

.form-group.select::after {
  top: 70%;
}

/* Focus */
.select select:focus {
  box-shadow: 0 0 0 0.075rem $white, 0 0 0 0.2rem $info;
}

/* Active/open */
.select select:active {
  color: $white;
  background-color: $info;
}

/* Hide the arrow in IE10 and up */
.select select::-ms-expand {
  display: none;
}

/* Media query to target Firefox only */
@-moz-document url-prefix() {
  /* Firefox hack to hide the arrow */
  .select select {
    padding-right: 1rem;
    text-indent: 0.01px;
    text-overflow: '';
  }

  /* <option> elements inherit styles from <select>, so reset them. */
  .select option {
    background-color: $white;
  }
}

/* IE9 hack to hide the arrow */
@media screen and (min-width: 0\0) {
  .select select {
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    z-index: 1;
  }

  .select::after {
    z-index: 5;
  }

  .select::before {
    background-color: $grey-decent;
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    right: 1rem;
    top: 0;
    width: 1.5rem;
    z-index: 2;
  }

  .select select:hover,
  .select select:focus,
  .select select:active {
    background-color: $grey-decent;
    color: $grey-dark;
  }
}

@include media-breakpoint-up(sm) {
  .select {
    display: block;
    max-width: 360px;

    select {
      width: 100%;
    }
  }
}
