@charset 'utf-8';

// ==========================================================================
// Import Vendor Files
// ==========================================================================

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700|Luckiest+Guy&display=swap');
// @import '../../node_modules/animate.css/animate'; // installed via yarn
// @import 'vendor/animate';
@import 'stylesheets/vendor/normalize';
// @import 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.css';
// @import 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.css';
// @import 'https://cdnjs.cloudflare.com/ajax/libs/photoswipe/4.1.2/photoswipe.min.css';
// @import 'https://cdnjs.cloudflare.com/ajax/libs/photoswipe/4.1.2/default-skin/default-skin.min.css';
//Animate CSS https://michalsnik.github.io/aos/
@import url('https://unpkg.com/aos@2.3.1/dist/aos.css');

// ==========================================================================
// Base - Style rules that sets the default CSS properties of individual HTML
//        elements.
// ==========================================================================
@import 'stylesheets/base/compatibility';
// @import 'stylesheets/base/awn-iconfont';
@import 'stylesheets/base/colors';
@import 'stylesheets/base/breakpoints';
@import 'stylesheets/base/animations';
@import 'stylesheets/base/container';
@import 'stylesheets/base/typography';
@import 'stylesheets/base/buttons';
@import 'stylesheets/base/form-checkboxes-radios';
@import 'stylesheets/base/form-input';
@import 'stylesheets/base/form-progress';
@import 'stylesheets/base/form-select';
@import 'stylesheets/base/form-validation';
@import 'stylesheets/base/form--radio-as-image';
@import 'stylesheets/base/flex';
@import 'stylesheets/base/grid';
@import 'stylesheets/base/headings';
@import 'stylesheets/base/helper';
@import 'stylesheets/base/images';
@import 'stylesheets/base/kecleon-iconfont';
@import 'stylesheets/base/lists';
@import 'stylesheets/base/progress-bar';
@import 'stylesheets/base/paragraphs';
@import 'stylesheets/base/spacings';
// @import 'stylesheets/base/rating';
// @import 'stylesheets/base/config'; // Modify this for custom colors, font-sizes, etc.
// @import 'stylesheets/base/mixins'; // Define custom mixins

// ==========================================================================
// Modules - Modular, reusable components.
// ==========================================================================
// @import 'stylesheets/modules/brand-item';
// @import 'stylesheets/modules/breadcrumb';
// @import 'stylesheets/modules/category-item';
// @import 'stylesheets/modules/collections-dropdown';
// @import 'stylesheets/modules/browser-warning';
// @import 'stylesheets/modules/discover-brands';
// @import 'stylesheets/modules/footer';
// @import 'stylesheets/modules/header';
// @import 'stylesheets/modules/hero';
// @import 'stylesheets/modules/topbar';
// @import 'stylesheets/modules/login';
// @import 'stylesheets/modules/modal';
// @import 'stylesheets/modules/nav-desktop';
// @import 'stylesheets/modules/nav-mobile';
// @import 'stylesheets/modules/newsletter';
// @import 'stylesheets/modules/notifications';
// @import 'stylesheets/modules/owl-carousel';
// @import 'stylesheets/modules/pagination';
// @import 'stylesheets/modules/popular-categories';
// @import 'stylesheets/modules/preview-bar';
// @import 'stylesheets/modules/product-feed';
// @import 'stylesheets/modules/product-item';
// @import 'stylesheets/modules/product-safety';
// @import 'stylesheets/modules/search-bar';
// @import 'stylesheets/modules/seo-content';
// @import 'stylesheets/modules/teasers';

// ==========================================================================
// Layout - Style rules that are related to the structural layout of web
//          pages. Containers, the grid, etc. They are prefixed with l-.
// ==========================================================================
@import 'stylesheets/layout/header';
@import 'stylesheets/layout/hero';
@import 'stylesheets/layout/cta';
@import 'stylesheets/layout/video';
@import 'stylesheets/layout/usp';
@import 'stylesheets/layout/image-list';
@import 'stylesheets/layout/testemonial';
@import 'stylesheets/layout/text-only';
@import 'stylesheets/layout/faq';
@import 'stylesheets/layout/contact';
@import 'stylesheets/layout/form';
@import 'stylesheets/layout/footer';
@import 'stylesheets/layout/modal';
// @import 'stylesheets/layout/tooltip';
// @import 'stylesheets/layout/l-404';
// @import 'stylesheets/layout/l-account';
// @import 'stylesheets/layout/l-brands';
// @import 'stylesheets/layout/l-cart';
// @import 'stylesheets/layout/l-collection-category';
// @import 'stylesheets/layout/l-guide';
// @import 'stylesheets/layout/l-landingpages';
// @import 'stylesheets/layout/l-list-collections';
// @import 'stylesheets/layout/l-login_register';
// @import 'stylesheets/layout/l-pages';
// @import 'stylesheets/layout/l-password';
// @import 'stylesheets/layout/l-products';
// @import 'stylesheets/layout/l-release-notes';
// @import 'stylesheets/layout/l-smartsearch';

// ==========================================================================
// State - Style rules that specify the current state of something in the
//         interface.
// ==========================================================================
@import 'stylesheets/state/hamburger-menu';
// @import 'stylesheets/state/basics';
// @import 'stylesheets/state/category-item';
// @import 'stylesheets/state/nav-desktop';
// @import 'stylesheets/state/pagination';
// @import 'stylesheets/state/product-variant';

// ==========================================================================
// Themes - Are style rules that affect layout and modules.
// ==========================================================================
@import 'stylesheets/themes/buttons';
// @import 'stylesheets/themes/awn-2019';
