// ==========================================================================
// USPs section
// ==========================================================================

.l-usp {
  .content {
    height: 100%;
    text-align: center;
  }

  .intro {
    margin: 2em;
  }

  .usp-block {
    width: 80%;
    margin: 0 auto 2em;

    p {
      margin: 1em 0.2em;
    }

    img {
      width: 30%;
      max-width: 140px;
    }
  }

  //---- no icons on mobile? -----
  .intro {
    margin: 2em 2em 0;
  }

  // .usp-block {
  //   margin: 0 auto;
  //   text-align: left;
  //
  //   img {
  //     display: none;
  //   }
  //
  //   p {
  //     margin-bottom: 0.3em;
  //   }
  // }

  a {
    margin-top: 2em;
  }
}

@include media-breakpoint-up(md) {
  .l-usp {
    .d-grid {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1em;

      .intro {
        grid-column: 1 / 5;
      }

      .usp-block {
        width: 100%;
        margin: 2em auto 0;
        text-align: center;

        img {
          display: block;
          margin: 0 auto;
        }

        p {
          font-size: 0.8em;
        }
      }

      a {
        grid-column: 1 / 5;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .l-usp .d-grid .usp-block p {
    font-size: 1em;
  }
}
