// ==========================================================================
// Flex Sheit
// ==========================================================================

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-item-start {
  align-items: flex-start;
}
