// ==========================================================================
// Vertical and horizontal lists
// ==========================================================================

ul {
  padding-left: 0;
  list-style-type: none;

  &.list-horizontal {
    li {
      display: inline-block;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.list-vertical {
    list-style-type: none;
    margin-bottom: 2rem;
    margin-top: 0;

    &.bullets {
      list-style-type: disc;
      margin-left: 1.25rem;
    }

    li {
      margin-bottom: 0.75rem;
      margin-top: 0.75rem;
    }

    strong {
      font-weight: $font-weight-semibold;
    }
  }
}
