// ==========================================================================
// Modal Classes
// ==========================================================================

.modal {
  z-index: 1001;
  display: none;
  padding-top: 60px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  margin: auto;
  background-color: #FFF;
  position: relative;
  outline: 0;
  width: 75%;
  height: auto;
  overflow: scroll;
  max-width: 600px;
  padding: 1em 2em;
  border-radius: 5px;
  overflow-wrap: break-word;
  word-wrap: break-word;

  a {
    color: $black;
  }

  h2 {
    font-size: 1.2em;
  }

  h2 span {
    color: $brand-3;
  }
}

.animate-opacity {
  animation: opac 0.8s;
}

.display-topright {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  font-size: 2.5em;
}

@keyframes opac {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@include media-breakpoint-up(md) {
  .modal-content h2 {
    font-size: 2em;
  }
}

#modal-privacy {
  .modal-content {
    height: 75%;
  }

  a {
    color: blue;
  }
}
