// ==========================================================================
// CSS animations
// ==========================================================================

// Define the minimum dimensions at which your layout will change,
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spin::before {
  animation: spinner 2s linear reverse infinite;
}

// add flashy background for event trigger
@keyframes flashy {
  0% { background-color: transparent; }
  20% { background-color: rgba($brand-3, 0.2); }
  100% { background-color: transparent; }
}

.flash {
  animation: flashy 1500ms ease-in-out;
  animation-delay: 100ms;
}
