// ==========================================================================
// Forms > Progress Bar, e.g. used in product-template.
// Based on http://wtfforms.com
// ==========================================================================

.progress {
  display: inline-block;
  height: 0.5rem;
}

.progress[value] {
  border-radius: 0.5rem;
  overflow: hidden;

  /* Reset the default appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Remove Firefox and Opera border */
  border: 0;

  /* IE10 uses `color` to set the bar background-color */
  color: $primary;
}

.progress[value]::-webkit-progress-bar {
  background-color: $grey-light;
  border-radius: 0.5rem;
}

.progress[value]::-webkit-progress-value {
  background-color: $primary;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.progress[value="100"]::-webkit-progress-value {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

/* Firefox styles must be entirely separate or it busts Webkit styles. */
@-moz-document url-prefix() {
  .progress[value] {
    background-color: $grey-light;
    border-radius: 0.5rem;
  }

  .progress[value]::-moz-progress-bar {
    background-color: $primary;
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }

  .progress[value="100"]::-moz-progress-bar {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
}

/* IE9 hacks to accompany custom markup. We don't need to scope this via media queries, but I feel better doing it anyway. */
@media screen and (min-width: 0\0) {
  .progress {
    background-color: $grey-light;
    border-radius: 0.5rem;
  }

  .progress-bar {
    display: inline-block;
    height: 1rem;
    text-indent: -999rem; /* Simulate hiding of value as in native `<progress>` */
    background-color: $primary;
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }

  .progress[width="100%"] {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
}
