// ==========================================================================
// form section
// ==========================================================================

.l-form {
  fieldset {
    border: 1px solid $black;
    padding: 10px;
    margin-bottom: 10px;
  }

  .form-group {
    label {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .l-form {
    .form-group {
      label {
        width: 49%;
      }
    }
  }
}
