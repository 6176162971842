@font-face {
  font-family: 'kecleon-iconfont';
  src: url("../font/kecleon-iconfont.eot?39099462");
  src:
    url("../font/kecleon-iconfont.eot?39099462#iefix") format('embedded-opentype'),
    url("../font/kecleon-iconfont.woff2?39099462") format('woff2'),
    url("../font/kecleon-iconfont.woff?39099462") format('woff'),
    url("../font/kecleon-iconfont.ttf?39099462") format('truetype'),
    url("../font/kecleon-iconfont.svg?39099462#kecleon-iconfont") format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */

/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'kecleon-iconfont';
    src: url('../font/kecleon-iconfont.svg?39099462#kecleon-iconfont') format('svg');
  }
}
*/
[class^="icon-"]::before,
[class*=" icon-"]::before {
  font-family: "kecleon-iconfont";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;

  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes */
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */

  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */

  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */

  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-streamline-icon-arrow-button-down-svgomg::before { content: '\e800'; }
.icon-streamline-icon-social-media-twitter-svgomg::before { content: '\e801'; }
.icon-streamline-icon-professional-network-linkedin-svgomg::before { content: '\e802'; }
.icon-streamline-icon-social-instagram-svgomg::before { content: '\e803'; }
.icon-streamline-icon-social-media-facebook-svgomg::before { content: '\e804'; }
.icon-streamline-icon-check-svgomg::before { content: '\e805'; }
.icon-streamline-icon-calendar-svgomg::before { content: '\e806'; }
.icon-streamline-icon-close-svgomg::before { content: '\e807'; }
.icon-streamline-icon-question-help-circle-svgomg::before { content: '\e808'; }
.icon-streamline-icon-pencil-2-svgomg::before { content: '\e809'; }
.icon-streamline-icon-button-refresh-arrows-svgomg::before { content: '\e80a'; }
