// ==========================================================================
// Font familis & font weights
// ==========================================================================

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-family-base: 'Roboto',Arial,Verdana,sans-serif;
$font-family-heading: 'Roboto',Arial,Verdana,sans-serif;
// $font-family-heading: 'Luckiest Guy', cursive;  // Default

body {
  font-family: $font-family-base;
  font-size: 1em;
  line-height: 1.5em;
}

// A site should only have 3 to 6 different font-sizes. If you have more than 6
// font sizes declared in your project, your users will not likely notice and
// you are making the site harder to maintain.
.font-xxl {
  font-size: 2.5em;
  line-height: 1.1em;
}

.font-xl {
  font-size: 2em;
  line-height: 1.1em;
}

.font-lg {
  font-size: 1.75em;
  line-height: 1.1em;
}

.font-md {
  font-size: 1.5em;
  line-height: 1.4em;
}

.font-sm {
  font-size: 1.25em;
  line-height: 1.2em;
}

.font-xs {
  font-size: 1em;
  line-height: 1.1em;
}

// Display headings are used to stand out more than normal headings.
.display-1 {
  font-size: 6em;
  // line-height: 1.1em;
}

.display-2 {
  font-size: 5.5em;
  // line-height: 1.1em;
}

.display-3 {
  font-size: 4.5em;
  // line-height: 1.1em;
}

.display-4 {
  font-size: 3.5em;
  // line-height: 1.1em;
}

//
.font-weight-bold {
  font-weight: bold;
}

.font-weight-bolder {
  font-weight: bolder;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-light {
  font-weight: light;
}

.font-weight-lighter {
  font-weight: lighter;
}

// Forms
// -------------------------
.form-input {
  color: $grey-default;
  font-size: 1rem;
  font-weight: $font-weight-regular;
  letter-spacing: 0.06px;
  line-height: 1.25rem;
}
