// ==========================================================================
// Buttons & Links
// ==========================================================================

.btn {
  &.btn-primary,
  &.btn-secondary {
    max-width: 290px;
  }
}
