// ==========================================================================
// Helper classes for better alignments
// ==========================================================================

.l-header {
  background-color: $brand-1;
  height: 80px;

  .content {
    height: 100%;
  }
}
