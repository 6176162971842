// ==========================================================================
// Colors
// ==========================================================================

// Non-Colors
// -------------------------
$white:             #FFF;
$grey-bright:       #F6F6F6;
$grey-decent:       #E6E6E6;
$grey-light:        #D8D8D8;
$grey-medium:       #878787;
$grey-default:      #777;
$grey-dark:         #555;
// $black-default:     #171318;
$black:             #000;

// Template Colors
// -------------------------
// $blue-sky:          #6CB8EE;
$primary:           #769F52;
$secondary:         #C45375;
// $gold:           #CFAA6D;
$brand-1:           #A8D38F;
$brand-2:           #4A803C;
$brand-3:           #BE541A;
$brand-4:           #91D241;

// Notification Colors
// -------------------------
$success:           #55B874;
$danger:            #D22940;
$attention:         #FFC963;
$info:              #3499D5;
