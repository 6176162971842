// ==========================================================================
// Footer Section
// ==========================================================================

.l-footer {
  background-color: $brand-4;
  padding: 2em 0 8em;

  .content {
    display: flex;
    flex-wrap: wrap;

    .footer-links {
      width: 100%;

      a {
        display: block;
        width: 100%;
        color: $white;
        margin: 0 0 1em;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .contact {
      margin: 0 auto;

      p {
        margin: 0;
        text-align: center;
        color: #FFF;
      }

      a {
        color: #FFF;
      }
    }

    .social-icons {
      margin: 0 auto;

      i {
        height: 40px;
        width: 40px;
        margin: 0 0.25em;
        font-size: 2em;
        color: $white;

        &::before {
          margin: 0;
        }

        &:hover {
          color: $black;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .l-footer {
    padding: 2em 0 5em;

    .content {
      justify-content: space-between;

      .footer-links {
        width: inherit;

        a {
          width: inherit;
          margin-right: 1em;
          display: inline;
        }
      }

      .contact {
        margin: 0;

        p {
          text-align: right;
        }
      }

      .social-icons {
        margin: 0;
      }
    }
  }
}
