// ==========================================================================
// Progress Bars
// ==========================================================================

.progress-bar-wrapper {
  margin-bottom: 1rem;

  h6 {
    font-size: 1rem;
    margin: 0 0 0.5rem;
  }

  div {
    border-radius: 12px;
  }

  .progress-bar {
    background-color: $grey-light;

    .progress-bar-inner {
      background-color: $brand-3;
      width: auto;
      height: 20px;
      font-size: 1rem;
      color: $white;
      text-align: right;
      padding: 0.25rem 0.75rem;
      line-height: 1.25rem;

      &.bar-01 {
        width: 32%;
      }

      &.bar-02 {
        width: 28%;
      }

      &.bar-03 {
        width: 24%;
      }

      &.bar-04 {
        width: 12%;
      }

      &.bar-05 {
        width: 8%;
      }
    }
  }
}
