// ==========================================================================
// Buttons & Links
// ==========================================================================

a {
  color: $primary;
  text-decoration: none;
}

.btn {
  border-color: transparent;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  cursor: pointer;
  //display: inline-block;
  display: block;
  font-size: 1.3em;
  line-height: 1.5;
  margin-left: auto;
  margin-right: auto;
  padding: 0.625rem 0.75rem;
  text-align: center;
  text-decoration: none;
  white-space: inherit;
  transition: all 0.3s;

  &.btn-primary,
  &.btn-secondary,
  &.btn-checkout {
    margin-bottom: 2rem;
    max-width: 260px;
    width: 100%;
  }

  &.btn-primary {
    background-color: $brand-3;
    border-color: $brand-3;
    color: $white;

    &:hover {
      background-color: darken($brand-3, 10%);
    }
  }

  &.btn-secondary {
    border-color: $grey-medium;
    color: $grey-default;

    &:hover {
      background-color: $grey-medium;
      color: $white;
    }
  }

  &.btn-checkout {
    background-color: $success;
    border-color: $success;
    color: $white;

    &:hover {
      background-color: darken($success, 10%);
    }
  }

  &:hover {
    transition: all 0.3s;
  }

  &[disabled] {
    background-color: $grey-default;
    border-color: $grey-default;
    color: $white;
    cursor: default;

    &:hover {
      background-color: $grey-default;
    }
  }
}
