// ==========================================================================
// Grid
// ==========================================================================

.d-grid {
  display: grid;
}

.grid-gap {
  &-sm {
    grid-gap: 0.5rem;
  }

  &-md {
    grid-gap: 1rem;
  }

  &-lg {
    grid-gap: 2rem;
  }
}
