// ==========================================================================
// Image with List
// ==========================================================================

.l-image-list {
  background-color: none;
  margin-top: 5em;

  .d-grid {
    grid-column: 1;
  }

  .intro {
    margin: 1em 0;
  }

  img {
    width: 70%;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.3) 0 2px 9px;
  }

  ul.list-vertical.bullets {
    list-style-type: none;
    margin-left: 2em;

    li {
      text-indent: -1.4em;
      hyphens: auto;

      i {
        color: $brand-3;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .l-image-list {
    .d-grid {
      grid-template-columns: 1fr 1fr;
      grid-gap: 3em;

      .intro {
        grid-column: 1 / 4;

        &.text-xs-center {
          text-align: left;
          margin: 0 0 1em;

          h2 {
            margin: 0 0 0.5em;
          }
        }
      }

      a {
        grid-column: 1 / 4;
      }
    }

    img {
      width: 100%;
    }
  }
}
