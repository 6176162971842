// ==========================================================================
// Forms > Select field, e.g. used in product-template.
// Based on http://wtfforms.com
// ==========================================================================

.form-group {
  margin-bottom: 1rem;
  margin-top: 1rem;

  .label--input {
    @extend h6;
  }

  &.append-btn {
    position: relative;

    input,
    .btn {
      line-height: 2;
      padding: 0.5rem 1rem;
    }

    input {
      position: relative;
      border-color: $white;
      z-index: 100;
    }

    .btn {
      background-color: $grey-decent;
      border: 1px solid $grey-decent;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 110;
    }
  }
}

input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $white;
  border-radius: 4px;
  border: 1px solid;
  color: $grey-dark;
  display: inline-block;
  line-height: 2.25;
  margin: 0;

  &[disabled] {
    background-color: $grey-decent;
    cursor: default;
  }
}

input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"] {
  box-sizing: border-box;
  cursor: pointer;
  padding: 0.5em 1em;
  width: 100%;
}

/* Undo the Firefox inner focus ring */
.select select:focus:-moz-focusring {
  // color: transparent;
  // text-shadow: 0 0 0 $black-default;
}

/* Dropdown arrow */
.select::after {
  position: absolute;
  top: 50%;
  right: 1.25rem;
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  margin-top: -0.15rem;
  pointer-events: none;
  border-top: 0.35rem solid;
  border-right: 0.35rem solid transparent;
  border-bottom: 0.35rem solid transparent;
  border-left: 0.35rem solid transparent;
}

/* Focus */
.select select:focus {
  // box-shadow: 0 0 0 0.075rem $white, 0 0 0 0.2rem $info;
}

/* Active/open */
.select select:active {
  // color: $white;
  // background-color: $info;
}

/* Hide the arrow in IE10 and up */
.select select::-ms-expand {
  // display: none;
}

textarea {
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
}

/* Media query to target Firefox only */
@-moz-document url-prefix() {
  /* Firefox hack to hide the arrow */
  .select select {
    // text-indent: 0.01px;
    // text-overflow: '';
    // padding-right: 1rem;
  }

  /* <option> elements inherit styles from <select>, so reset them. */
  .select option {
    // background-color: $white;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
