// ==========================================================================
// Text Only section
// ==========================================================================

.l-text-only {
  background-color: none;
  margin: 2em 0;

  .content {
    max-width: 600px;
    margin: 0 auto;
    background: $grey-bright;
    padding: 0 1em;

    a {
      margin-top: 1em;
    }
  }

  .intro {
    margin: 1em 0;
  }
}

@include media-breakpoint-up(md) {
  .l-text-only {
    .content {
      padding: 0 4em;
    }
  }
}
