// ==========================================================================
// Video section
// ==========================================================================

.l-video {
  background-color: $brand-4;

  .content {
    height: 100%;
    text-align: center;
  }

  .d-grid {
    grid-column: 1;
  }

  .video {
    margin: 2em auto;
    max-width: 485px;

    h2 {
      color: $white;
    }

    iframe {
      border: none;
      box-shadow: #4A803C 0 6px 12px;
    }
  }
}

@include media-breakpoint-up(md) {
  .l-video {
    .video {
      max-width: 600px;
    }
  }
}
