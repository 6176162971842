// ==========================================================================
// Headings Styles
// ==========================================================================

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .font-weight-bold;

  font-family: $font-family-heading;
}

h1 {
  @extend .font-weight-bolder;
  @extend .font-xxl;
  // color: $black-default;
  // font-weight: $font-weight-semibold;
  // letter-spacing: 0.02px;
  // margin-bottom: 0.5em;
}

h2 {
  @extend .font-xl;
  // color: $black-default;
  // font-weight: $font-weight-semibold;
  // letter-spacing: 0.02px;
  // margin-bottom: 1.375em;
  // margin-top: 1.375em;
}

h3 {
  @extend .font-lg;
  // color: $black-default;
  // font-weight: $font-weight-bold;
  // letter-spacing: 0.03px;
}

h4 {
  @extend .font-md;
  // color: $black-default;
  // font-weight: $font-weight-bold;
  // letter-spacing: 0.02px;
}

h5 {
  @extend .font-sm;
  // color: $grey-dark;
  // font-weight: $font-weight-semibold;
  // letter-spacing: 0.03px;
  // margin-bottom: 1em;
  // margin-top: 1em;
  // text-transform: uppercase;
}

h6 {
  @extend .font-xs;
  // color: $black-default;
  // display: inline-block;
  // font-weight: $font-weight-semibold;
  // margin-bottom: 0.5em;
  // text-transform: uppercase;
}

// Fake headings
// -------------------------
.h-1 {
  @extend h1;

  font-family: $font-family-heading;
}

.h-2 {
  @extend h2;

  font-family: $font-family-heading;
}

.h-3 {
  @extend h3;

  font-family: $font-family-heading;
}

.h-4 {
  @extend h4;

  font-family: $font-family-heading;
}

.h-5 {
  @extend h5;

  font-family: $font-family-heading;
}

@include media-breakpoint-up(md) {

  // h1 {
  //   font-size: 2rem;
  //   letter-spacing: 0.03px;
  //   line-height: 2.438rem;
  //   margin-bottom: 2rem;
  // }
  //
  // h2 {
  //   font-size: 1.625rem;
  //   line-height: 2.188rem;
  // }
  //
  // h3 {
  //   font-size: 1.438rem;
  //   line-height: 1.813rem;
  // }
  //
  // h4 {
  //   font-size: 1.188rem;
  //   line-height: 1.625rem;
  // }
}

@include media-breakpoint-up(xl) {
  // h3 {
  //   font-size: 1.625rem;
  //   line-height: 2.063rem;
  // }
}
