// ==========================================================================
// Hamburger Menu
// ==========================================================================

.btn12 {
  height: 30px;
  transition-duration: 0.5s;
  width: 30px;

  .icon {
    transition-duration: 0.5s;
    position: absolute;
    height: 4px;
    width: 30px;
    top: 38px;
    background-color: $black;

    &::before {
      transition-duration: 0.5s;
      position: absolute;
      width: 30px;
      height: 4px;
      background-color: $black;
      content: "";
      top: -10px;
    }

    &::after {
      transition-duration: 0.5s;
      position: absolute;
      width: 30px;
      height: 4px;
      background-color: $black;
      content: "";
      top: 10px;
    }
  }

  &.open {
    .icon {
      transition-duration: 0.5s;
      background: transparent;

      &::before {
        transform: rotateZ(45deg) scaleX(1.25) translate(6.5px, 6.5px);
      }

      &::after {
        transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -6px);
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}
