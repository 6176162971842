// ==========================================================================
// Forms > Select field, e.g. used in product-template.
// Based on http://wtfforms.com
// ==========================================================================

.radio-image-selector {
  fieldset {
    display: inline-block;
    padding: 0;
  }

  input {
    -moz-appearance: radio;
    -webkit-appearance: radio;
    appearance: radio;
    border: 0;
    position: absolute;
    width: inherit;
    z-index: 60;
  }

  input:checked + label {
    -webkit-filter: none;
    -moz-filter: none;
    filter: none;
  }

  label {
    display: inline-table;
    text-align: center;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 70px;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    // -webkit-filter: brightness(1) grayscale(0.5) opacity(1);
    // -moz-filter: brightness(1) grayscale(0.5) opacity(1);
    // filter: brightness(1) grayscale(0.5) opacity(1);
    background-color: $grey-bright;
    border: 1px solid $grey-light;
    border-radius: 4px;

    img {
      box-shadow: 0 1px 2px 0 rgba($black, 0.6);
      margin: 0.5em 0.5em 0;
      width: calc(100% - 1em);
    }

    &:hover {
      // -webkit-filter: brightness(1.2) grayscale(0) opacity(1);
      // -moz-filter: brightness(1.2) grayscale(0) opacity(1);
      // filter: brightness(1.2) grayscale(0) opacity(1);
    }
  }
}

@include media-breakpoint-up(sm) {
  .radio-image-selector {
    label img {
      margin: 0.5em 1.5em 0;
      width: calc(100% - 3em);
    }
  }
}
