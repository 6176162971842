// ==========================================================================
// Container & Content
// ==========================================================================

// HINT!
// Always use .container as parent and
// .content as content as sibling.

.container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.container-sm {
  max-width: 500px;
}

.container-md {
  max-width: 800px;
}

.container-lg {
  max-width: 1440px;
}

.content {
  padding-left: 0.75em;
  padding-right: 0.75em;
  max-width: 1024px;
  margin: 0 auto;
}
